import { getGlobalParamsOptionsAsync } from '@/utils/common'

export const baseViewFormConfig = {
  labelWidth: '140px',
  itemStyle: {
    padding: '5px 20px'
  },
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'general.name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'tableId',
      type: 'select',
      label: 'Template',
      isResolveGlobalParams: true,
      options: [],
      handler: () =>
        getGlobalParamsOptionsAsync('paramTemplate', {
          isNeedPreFix: false
        }),
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'groupId',
      type: 'select',
      label: 'Template Group',
      isResolveGlobalParams: true,
      options: [],
      handler: () =>
        getGlobalParamsOptionsAsync('terminalParamGroup', {
          isNeedPreFix: false
        }),
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'tenantId',
      type: 'input',
      label: 'user.tenant',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      otherOptions: {
        maxlength: 200,
        disabled: true
      }
    }
  ],
  validateRules: {}
}
